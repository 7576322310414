// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-background-literature-js": () => import("./../../../src/pages/background-literature.js" /* webpackChunkName: "component---src-pages-background-literature-js" */),
  "component---src-pages-conceptual-framework-js": () => import("./../../../src/pages/conceptual-framework.js" /* webpackChunkName: "component---src-pages-conceptual-framework-js" */),
  "component---src-pages-findings-js": () => import("./../../../src/pages/findings.js" /* webpackChunkName: "component---src-pages-findings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recommendations-js": () => import("./../../../src/pages/recommendations.js" /* webpackChunkName: "component---src-pages-recommendations-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-internal-js": () => import("./../../../src/templates/internal.js" /* webpackChunkName: "component---src-templates-internal-js" */)
}

